<script>
import axios from 'axios';
// // import Switches from "vue-switches";
// import Swal from 'sweetalert2';
import Swal from 'sweetalert2';
const moment = require('moment');
import BigNumber from 'bignumber.js'
// import _ from "lodash";
import { required } from "vuelidate/lib/validators";

export default {
  props: [ 'loadOptionDataParent' ],
  components: {
  },
  mounted () {
    
  },
  data() {
    return {
      billingCycleList: [
        {
          text: 'One Time',
          value: 'onetime',
          days: 1
        },
        {
          text: 'Monthly',
          value: '1month',
          days: 30
        },
        {
          text: 'Every 3 Months',
          value: '3month',
          days: 90
        },
        {
          text: 'Every 4 Months',
          value: '4month',
          days: 120
        },
        {
          text: 'Every 6 Months',
          value: '6month',
          days: 180
        },
        {
          text: 'Anually',
          value: '1year',
          days: 360
        },
        {
          text: 'Every 2 Year',
          value: '2year',
          days: 720
        },
        {
          text: 'Every 3 Year',
          value: '3year',
          days: 1080
        }
      ],
      statusList: [
        {
          text: 'Pending',
          value: 'pending'
        },
        {
          text: 'Active',
          value: 'active'
        },
        {
          text: 'Completed',
          value: 'complete'
        },
        {
          text: 'Suspended',
          value: 'suspend'
        },
        {
          text: 'Terminated',
          value: 'terminated'
        },
        {
          text: 'Cancelled',
          value: 'cancel'
        },
        {
          text: 'Fraud',
          value: 'fraud'
        }
      ],
      listProduct: [],
      title: 'Tambah Produk',
      submittedForm: false,
      data: {
        productData: {
          productStatus: {
            status: 'pending'
          }
        },
        transactionData: {
          price: 0,
          cost: 0,
          recurringAmount: 0,
          firstPayment: 0,
          registeredPackage: moment().format('yyyy-MM-DD'), // .add(1, 'M')
          nextDue: moment().add(1, 'M').endOf('month').format('yyyy/MM') + '/20',
          invoiceDate: moment().add(1, 'M').endOf('month').format('yyyy-MM') + '-05',
          prorata: false,
          billingCycle: '1month'
        },
        notes: '',
        collector: '',
        notificationOnPayment: {
          whatsapp: {
            enable: false,
            priority: 0
          },
          sms: {
            enable: false,
            priority: 0
          },
          email: {
            enable: false,
            priority: 0
          }
        },
        notificationOnInvoice: {
          whatsapp: {
            enable: false,
            priority: 0
          },
          sms: {
            enable: false,
            priority: 0
          },
          email: {
            enable: false,
            priority: 0
          }
        }
      },
      choice: {
        product: {
          text: 'Pilih',
          value: ''
        }
      }
    }
  },
  methods: {
    show (id) {
      console.log('param modal', this.$route.params.id)
      this.getData()
      console.log('idnya', id)
      this.$refs.modal.show();
    },
    async getData () {
      this.data.collector = this.$store.state.authfack.user.id
      await axios.get(process.env.VUE_APP_URL_FEATHERS + 'customer-data?customerId=' + this.$route.params.id).then(async response => {
        // console.log("sini", response.data);
        await axios.get(process.env.VUE_APP_URL_FEATHERS + 'client-grup?clientGroup=' + response.data.clientGroup).then(async response2 => {
          this.data.notificationOnPayment = response2.data.notificationOnPayment
          this.data.notificationOnInvoice = response2.data.notificationOnInvoice
          this.listProduct = await axios.get(process.env.VUE_APP_URL_FEATHERS + 'product?clientGroup=' + response2.data.id).then(response3 => {
            // console.log("list prod", response3.data);
            return response3.data.map(function (val) {
              return {
                text: val.codeProduct + ' - ' + val.name,
                value: val
              }
            })
          });
        });
      });
      // const mapped = _.map(this.$store.state.authfack.user.clientgrup, 'refId')
      console.log('lsit prod', this.data.transactionData.registeredPackage)
    },
    async restartDevice () {
      const body = {
        orderId: this.data.orderId
      }
      
      // eslint-disable-next-line no-unused-vars
      await axios.post(process.env.VUE_APP_URL_FEATHERS + 'costumer-products?restart-device=true', body, { headers: { 'X-HTTP-Method-Override': 'POST' } }).then(response => {
        console.log("sini", response.data);
        Swal.fire(
            'Sukses!',
            'Berhasil Melakukan Restart',
            'success'
        )
      }).catch(error=>{
        Swal.fire(
            'Gagal!',
            error.message,
            'error'
        )
      });
    },
    resetModal () {
      console.log('reset')
      this.choice = {
        product: {
          text: 'Pilih',
          value: ''
        }
      }
      this.data = {
        productData: {
          productStatus: {
            status: 'pending'
          }
        },
        transactionData: {
          price: 0,
          cost: 0,
          recurringAmount: 0,
          firstPayment: 0,
          registeredPackage: moment().format('yyyy-MM-DD'), // .add(1, 'M')
          nextDue: moment().add(1, 'M').endOf('month').format('yyyy/MM') + '/20',
          invoiceDate: moment().add(1, 'M').endOf('month').format('yyyy-MM') + '-05',
          prorata: false,
          billingCycle: '1month',
        },
        notes: '',
        collector: '',
        notificationOnPayment: {
          whatsapp: {
            enable: false,
            priority: 0
          },
          sms: {
            enable: false,
            priority: 0
          },
          email: {
            enable: false,
            priority: 0
          }
        },
        notificationOnInvoice: {
          whatsapp: {
            enable: false,
            priority: 0
          },
          sms: {
            enable: false,
            priority: 0
          },
          email: {
            enable: false,
            priority: 0
          }
        }
      }
    },
    async submit () {
      this.submittedForm = true;
      // stop here if form is invalid
      this.data.customerId = this.$route.params.id
      this.$v.data.$touch();
      if (this.$v.data.$invalid) {
        // console.log('gagal', this.$v)
        // console.log('gagal', this.data)
        return;
      } else { 
        // console.log('berhasil', this.data)
        await axios.post(process.env.VUE_APP_URL_FEATHERS + 'costumer-products?create-product=true', this.data)
        // eslint-disable-next-line no-unused-vars
          .then(async response => {
            // console.log('resp', response)
            this.$emit('loadOptionDataParent')
            Swal.fire("Sukses!", "Data sukses disimpan!", "success");
            this.$refs['modal'].hide()
          }).catch(error=>{
            // console.error(error)
            Swal.fire("Gagal!", error.message, "error");
          })
      }
    },
    prorataFunc (pr) {
      if (pr) {
        
        const basePrice = new BigNumber(this.data.transactionData.price)
        var start = moment(this.data.transactionData.registeredPackage)
        var end = moment(moment().endOf('month').format('YYYY/MM/DD')) 
       
        var totalDate = moment.duration(end.diff(start)).asDays()
        
        const cycIndex = this.billingCycleList.findIndex(x => x.value === this.data.transactionData.billingCycle)
        const billCycData = this.billingCycleList[cycIndex]
       
        this.data.transactionData.firstPayment = basePrice.dividedBy(billCycData.days).times(totalDate).plus(this.data.transactionData.cost).toFixed(0)
      } else {
      
        this.data.transactionData.firstPayment = new BigNumber(this.data.transactionData.price).plus(this.data.transactionData.cost).toFixed()
      }
    },
  },
  watch: {
    'choice.product': {
      immediate: true,
      handler (newValue) {
        if (newValue === 0 || newValue) {
          this.data.productData.products = newValue._id
          this.data.transactionData.price = newValue.price ? newValue.price : 0
          this.data.transactionData.cost = newValue.cost ? newValue.cost : 0
          this.data.transactionData.firstPayment = Number(this.data.transactionData.cost) + Number(this.data.transactionData.price)
          this.data.transactionData.recurringAmount = Number(this.data.transactionData.price)
          // console.log('reg', this.data.transactionData.registeredPackage)
          // console.log('inv date', this.data.transactionData.invoiceDate)
        }
      }
    },
    'data.transactionData.prorata': {
      immediate: true,
      handler (newValue) {
        // console.log('asd')
        this.prorataFunc(newValue)
      }
    },
    'data.transactionData.cost': {
    handler(newValue) {
    console.log('asd', newValue);

    if (!newValue || isNaN(Number(newValue))) {
      this.$set(this.data.transactionData, 'cost', 0);
    }

    this.prorataFunc(newValue);
  }
}

  },
  validations: {
    data: {
      productData: {
        products: {
          required
        },
        productStatus: {
          status: {
            required
          }
        }
      },
      transactionData: {
        price: {
          required
        },
        cost: {
          required
        },
        recurringAmount: {
          required
        },
        firstPayment: {
          required
        },
        registeredPackage: {
          required
        },
        invoiceDate: {
          required
        },
        prorata: {
          required
        },
        billingCycle: {
          required
        }
      },
    }
  }, 
};
</script>
  
<template>
  <b-modal
      id="modal"
      ref="modal"
      centered
      title="Form Tambah Produk"
      title-class="font-18"
      size="lg"
      @hide="resetModal"
    >
      <form ref="form" @submit.prevent="submit">
        <div class="row">
          <div class="col">
            <b-form-group
              class="mb-3"
              id="example text"
              label="Produk"
              label-for="text"
            >
            <b-form-select
                for="text"
                style="width: 100%; height: 40px;"
                v-model="choice.product"
                :options="listProduct"
                :class=" {
                        'is-invalid': submittedForm && $v.data.productData.products.$error,
                      }"
              ></b-form-select>
              <div
                v-if="submittedForm && $v.data.productData.products.$error"
                class="invalid-feedback"
              >
                <span v-if="!$v.data.productData.products.required"
                  >Produk tidak boleh kosong!</span
                >
              </div>
            </b-form-group>
            <b-form-group
              class="mb-3"
              id="example text"
              label="Harga"
              label-for="text"
            >
              <b-form-input
                for="text"
                type="number"
                v-model="data.transactionData.price"
                :class="{
                        'is-invalid': submittedForm && $v.data.transactionData.price.$error,
                      }"
              ></b-form-input>
              <div
                v-if="submittedForm && $v.data.transactionData.price.$error"
                class="invalid-feedback"
              >
                <span v-if="!$v.data.transactionData.price.required"
                  >Harga tidak boleh kosong!</span
                >
              </div>
            </b-form-group>
            <b-form-group
              class="mb-3"
              id="example text"
              label="Biaya Instalasi"
              label-for="text"
            >
            <b-form-input
                for="text"
                type="number"
                v-model="data.transactionData.cost"
                :class="{
                        'is-invalid': submittedForm && $v.data.transactionData.cost.$error,
                      }"
              ></b-form-input>
              <div
                v-if="submittedForm && $v.data.transactionData.cost.$error"
                class="invalid-feedback"
              >
                <span v-if="!$v.data.transactionData.cost.required"
                  >Biaya instalasi tidak boleh kosong!.</span
                >
              </div>
            </b-form-group>
            <b-form-group
              class="mb-3"
              id="example text"
              label="Tanggal Registrasi"
              label-for="date"
            >
              <b-form-input
                id="date"
                for="text"
                type="date"
                value="2023/09/01"
                v-model="data.transactionData.registeredPackage"
                :class="{
                        'is-invalid': submittedForm && $v.data.transactionData.registeredPackage.$error,
                      }"
              ></b-form-input>
              <div
                v-if="submittedForm && $v.data.transactionData.registeredPackage.$error"
                class="invalid-feedback"
              >
                <span v-if="!$v.data.transactionData.registeredPackage.required"
                  >Tanggal registrasi tidak boleh kosong!</span
                >
              </div>
            </b-form-group>
            <b-form-group
              class="mb-3"
              id="example text"
              label="Status"
              label-for="text"
            >
              <b-form-select
                for="text"
                style="width: 100%; height: 40px;"
                v-model="data.productData.productStatus.status"
                :options="statusList"
                :class=" {
                        'is-invalid': submittedForm && $v.data.productData.productStatus.status.$error,
                      }"
              ></b-form-select>
              <div
                v-if="submittedForm && $v.data.productData.productStatus.status.$error"
                class="invalid-feedback"
              >
                <span v-if="!$v.data.productData.productStatus.status.required"
                  >Status tidak boleh kosong!</span
                >
              </div>
            </b-form-group>
          </div>
          <div class="col">
            
            <b-form-group
              class="mb-3"
              id="example text"
              label="Pembayaran Pertama"
              label-for="text"
            >
              <b-form-input
                for="text"
                type="number"
                disabled
                v-model="data.transactionData.firstPayment"
                :class="{
                        'is-invalid': submittedForm && $v.data.transactionData.firstPayment.$error,
                      }"
              ></b-form-input>
              <div
                v-if="submittedForm && $v.data.transactionData.firstPayment.$error"
                class="invalid-feedback"
              >
                <span v-if="!$v.data.transactionData.firstPayment.required"
                  >Pembayaran pertama tidak boleh kosong!</span
                >
              </div>
            </b-form-group>
            
            <div
              class="form-check form-switch form-switch-md mb-3"
              dir="ltr"
            >
              <input
                type="checkbox"
                class="form-check-input"
                id="customSwitchsizemd"
                v-model="data.transactionData.prorata"
              />
              <label class="form-check-label" for="customSwitchsizemd"
                >Prorata</label
              >
            </div>
            <b-form-group
              class="mb-3"
              id="example text"
              label="Biaya Bulanan"
              label-for="text"
            >
              <b-form-input
                disabled
                type="number"
                for="text"
                v-model="data.transactionData.recurringAmount"
                :class="{
                        'is-invalid': submittedForm && $v.data.transactionData.recurringAmount.$error,
                      }"
              ></b-form-input>
              <div
                v-if="submittedForm && $v.data.transactionData.recurringAmount.$error"
                class="invalid-feedback"
              >
                <span v-if="!$v.data.transactionData.recurringAmount.required"
                  >Biaya bulanan tidak boleh kosong!</span
                >
              </div>
            </b-form-group>
            <b-form-group
              class="mb-3"
              id="example text"
              label="Tanggal Invoice"
              label-for="text"
            >
              <b-form-input
                for="text"
                type="date"
                v-model="data.transactionData.invoiceDate"
                :class="{
                        'is-invalid': submittedForm && $v.data.transactionData.invoiceDate.$error,
                      }"
              ></b-form-input>
              <div
                v-if="submittedForm && $v.data.transactionData.invoiceDate.$error"
                class="invalid-feedback"
              >
                <span v-if="!$v.data.transactionData.invoiceDate.required"
                  >Tanggal invoice tidak boleh kosong!.</span
                >
              </div>
            </b-form-group>
            <textarea
                v-model="data.notes"
                class="form-control"
                :maxlength="225"
                rows="3"
                placeholder="Catatan...."
            ></textarea>
          </div>
        </div>
      </form>
      <template #modal-footer>
        <b-button  variant="primary" @click="submit()">
          Simpan
        </b-button>
      </template>
    </b-modal>
</template>
  